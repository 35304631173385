import React from 'react'
import Helmet from 'react-helmet'
import {Link} from 'gatsby'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import { GalleryPageImages } from '../components/Gallery/constants/galleryPageImages'


const HomeIndex = () => {
  const siteTitle = 'Ffynnon Felen - Luxury Accomodation'
  const siteDescription = '10 guest accomodation in the heart of West Wales'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
                <section id="two">
          <h2>Gallery</h2>

          <Gallery images={GalleryPageImages}/>

          <ul className="actions">
            <li>
              <Link to="/" className="button">
                Home
              </Link>
            </li>
          </ul>
        </section>

        
      </div>
    </Layout>
  )
}

export default HomeIndex
